define(["jquery", "bootstrap"], function(jQuery) {
    var modal;
    jQuery(document).ready(function() {
        modal = jQuery("#genericModal");
    });

    function loadAjaxContent(url) {
        jQuery.ajax({
            url: url,
            dataType: "json",
            success: function(data) {
                handleAjaxResponse(data);
            },
            error: function() {
                window.location.reload(true);
            },
        });
    }

    function handleAjaxResponse(data) {
        if (data.action === "redirect") {
            window.location.href = data.location;
        } else if (data.action === "follow") {
            loadAjaxContent(data.location);
        } else if (data.action === "replace") {
            modal.find(".modal-content").replaceWith(data.content);
            modal.modal("handleUpdate");
        } else {
            modal.find(".modal-content").replaceWith(data);
        }
    }

    function bindModalLinks() {
        jQuery('*[data-toggle="ajax-modal"]')
            .off()
            .on("click", function(event) {
                const trigger = jQuery(this);
                if (trigger.attr("href") !== undefined) {
                    loadAjaxContent(trigger.attr("href"));
                }
                modal.modal("show");
                event.preventDefault();
                return false;
            });
        jQuery("a")
            .filter('[href*="' + window.location.host + '"]')
            .filter('[href*="/account/login/"], [href*="/account/register/"]')
            .off()
            .on("click", function(event) {
                const trigger = jQuery(this);
                if (trigger.attr("href") !== undefined) {
                    loadAjaxContent(trigger.attr("href"));
                }
                modal.modal("show");
                event.preventDefault();
                return false;
            });
    }

    function bindModalForm() {
        jQuery(".modal form")
            .not(".disable-ajax")
            .off()
            .on("submit", function(event) {
                var form = jQuery(this);
                var formData = new FormData(this);

                if (form.attr("action") !== undefined) {
                    jQuery.ajax({
                        url: form.attr("action"),
                        type: form.attr("method"),
                        dataType: "json",
                        contentType: false,
                        cache: false,
                        processData: false,
                        data: formData,
                        success: function(data) {
                            handleAjaxResponse(data);
                        },
                    });
                }
                event.preventDefault();
                return false;
            });
    }

    function initAjaxContent() {
        bindModalLinks();
        bindModalForm();
        modal.modal("handleUpdate");
    }

    jQuery(document).ready(function() {
        initAjaxContent();
    });
    jQuery(document).ajaxComplete(function() {
        initAjaxContent();
    });
});
