import jQuery from "jquery";

jQuery(document).ready(function() {
    if (jQuery(".section-price").length) {
        bindRemoveHighlight();
        setTimeout(resizePricingCards, 300);
        setTimeout(resizePricingCards, 1000);
    }
});

function bindRemoveHighlight() {
    jQuery(".section-price .card-deck").on("mouseenter mouseleave", function() {
        jQuery(".section-price .card.highlight").removeClass("highlight");
    });
}

function resizePricingCards() {
    let max_title_height = 0;
    let max_features_height = 0;
    let max_info_height = 0;

    jQuery(".section-price .card-title").each(function() {
        let self = jQuery(this);
        if (self.height() > max_title_height) {
            max_title_height = self.height();
        }
    });
    jQuery(".section-price .list-features").each(function() {
        let self = jQuery(this);
        if (self.height() > max_features_height) {
            max_features_height = self.height();
        }
    });
    jQuery(".section-price .info-text").each(function() {
        let self = jQuery(this);
        if (self.height() > max_info_height) {
            max_info_height = self.height();
        }
    });

    jQuery(".section-price .card-title").each(function() {
        let self = jQuery(this);
        if (max_title_height > 0) {
            self.height(max_title_height);
        }
    });
    jQuery(".section-price .list-features").each(function() {
        let self = jQuery(this);
        if (max_features_height > 0) {
            self.height(max_features_height);
        }
    });
    jQuery(".section-price .info-text").each(function() {
        let self = jQuery(this);
        if (max_info_height > 0) {
            self.height(max_info_height);
        }
    });
}
