define(["jquery"], function(jQuery) {
    function bindFormLockOnSubmit() {
        jQuery("form").on("submit", function(event) {
            var form = jQuery(this);
            form.find(":input").each(function() {
                var input = jQuery(this);
                input.prop("readonly", true);
            });
            return true;
        });
    }

    function bindTextareaResize() {
        jQuery("textarea.form-control").on("focus", function(event) {
            var textarea = jQuery(this);
            textarea.css("height", "10rem");
        });
    }

    function initFormHandling() {
        bindFormLockOnSubmit();
        bindTextareaResize();
    }

    jQuery(document).ready(function() {
        initFormHandling();
    });
    jQuery(document).ajaxComplete(function() {
        initFormHandling();
    });
});
