import jQuery from "jquery";

jQuery(document).ready(function() {
    jQuery(".dropdown-hoverable").hover(
        function() {
            jQuery(this)
                .children('[data-toggle="dropdown"]')
                .click();
        },
        function() {
            jQuery(this)
                .children('[data-toggle="dropdown"]')
                .click();
        }
    );
});
