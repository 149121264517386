// Visibility
define(["jquery"], function(jQuery) {
    function _conditionHandler() {
        var conditionField = jQuery(this);
        var element = jQuery(
            "[data-visibility='conditional']" +
                "[data-condition-field='" +
                conditionField.attr("name") +
                "']",
        );
        var conditionValue = element.data("condition-value");
        if (conditionField !== undefined) {
            if (conditionField.val() === conditionValue) {
                element.fadeIn();
            } else {
                element.fadeOut();
            }
        }
    }

    function initConditionalElements() {
        jQuery("[data-visibility='conditional']").each(function() {
            var conditionFields = jQuery(
                "[name='" + jQuery(this).data("condition-field") + "']",
            );
            conditionFields
                .off("change.conditionalVisibility")
                .on("change.conditionalVisibility", _conditionHandler);
            conditionFields.trigger("change.conditionalVisibility");
        });
    }

    jQuery(document).ready(function() {
        initConditionalElements();
    });
    jQuery(document).ajaxComplete(function() {
        initConditionalElements();
    });
});
