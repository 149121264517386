define(["jquery", "html5sortable/dist/html5sortable.cjs.js"], function(
    jQuery,
    sortable,
) {
    // FIXME sortable is no function

    function initCompetenceOrderLIst() {
        var competenceOrderList = jQuery(".competence-order-list");
        if (competenceOrderList.length) {
            deactivateLowPriorityCompetences();
            sortable(".competence-order-list");

            competenceOrderList[0].addEventListener("sortupdate", function(e) {
                /*

                This event is triggered when the user stopped sorting and the DOM position has changed.

                e.detail.item contains the current dragged element.
                e.detail.index contains the new index of the dragged element (considering only list items)
                e.detail.oldindex contains the old index of the dragged element (considering only list items)
                e.detail.elementIndex contains the new index of the dragged element (considering all items within sortable)
                e.detail.oldElementIndex contains the old index of the dragged element (considering all items within sortable)
                e.detail.startparent contains the element that the dragged item comes from
                e.detail.endparent contains the element that the dragged item was added to (new parent)
                e.detail.newEndList contains all elements in the list the dragged item was dragged to
                e.detail.newStartList contains all elements in the list the dragged item was dragged from
                e.detail.oldStartList contains all elements in the list the dragged item was dragged from BEFORE it was dragged from it
                */
                updateCounter();
                deactivateLowPriorityCompetences();
            });
        }
    }

    function updateCounter() {
        var competenceOrderList = jQuery(".competence-order-list");
        if (competenceOrderList.length) {
            var competences = competenceOrderList.children();
            competences.each(function(index) {
                var counter = jQuery(this).find(".counter");
                counter.html(index + 1);
                var counterInput = jQuery(this).find(
                    'input[data-value="counter"]',
                );
                counterInput.val(index + 1);
            });
        }
    }

    function deactivateLowPriorityCompetences() {
        var competenceOrderList = jQuery(".competence-order-list");
        if (competenceOrderList.length) {
            var maxCount = competenceOrderList.data("max-count");
            var competences = competenceOrderList.children();

            competences.each(function(index) {
                var competence = jQuery(this);
                if (index + 1 > maxCount) {
                    competence.addClass("inactive");
                } else {
                    competence.removeClass("inactive");
                }
            });
        }
    }

    jQuery(document).ready(function() {
        initCompetenceOrderLIst();
    });
    jQuery(document).ajaxComplete(function() {
        initCompetenceOrderLIst();
    });
});
